import React from 'react';
import Pagefit from 'Component/globals/pagefit';
import Footer from 'Component/footers/footer';
import Mile from 'Component/globals/mile';

function Miles() {
  return(
    <React.Fragment>
    <Mile/>
    <Pagefit/>
    <Footer/>
    </React.Fragment>
  );
}
export default Miles;
