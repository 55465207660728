import { useState, useContext } from 'react';
import Icofont from 'react-icofont';
import { Link, useHistory } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { SessionsContext } from 'Context/sessionsContext';
import { CountriesContext } from 'Context/countriesContext';
import { TimezonesContext } from 'Context/timezonesContext';
import $ from "jquery";
import { useCookies } from 'react-cookie';

function Myaccount() {
  let history = useHistory();
  const [cookies] = useCookies([
    'malvi',
  ], []);
  const {sessions, updateSessionsData} = useContext(SessionsContext);
  const {countries} = useContext(CountriesContext);
  const {timezones} = useContext(TimezonesContext);
  const gender = sessions.client.gender;
  const [hidden, setHidden] = useState("hidden");
  if(cookies.malvi === undefined) {
    history.push('/login')
  }

  const [values, setValues] = useState({
        phone: sessions.client.phone,
        city: sessions.client.city,
        address: sessions.client.address,
        coid:sessions.client.coid,
        tz:sessions.client.tz
  });

  const handleClick = () => {
    if(values.phone === undefined || values.city === undefined || values.address === undefined || values.coid === undefined || values.tz === undefined ) {
        setValues({phone: sessions.client.phone, city: sessions.client.city, address:sessions.client.address, coid:sessions.client.coid, tz:sessions.client.tz})
    }
  }

  const handleChange = name => e => {
      setValues({ ...values, [name]: e.target.value });
      setHidden("")
  };

  const handleSubmit = (e) => {
    e.preventDefault();
      $(".loaderBar").show();
      updateSessionsData(values.phone, values.city, values.address, values.coid, values.tz)
  }

  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="myaccountPage">
      <Form onSubmit={handleSubmit}>
        <Form.Row>
          <Form.Group as={Col} controlId="cardId">
            <Form.Label>Kimlik</Form.Label>
            <Form.Control type="text" readOnly placeholder="0123456789" defaultValue={sessions.client.cardid} />
          </Form.Group>

          <Form.Group as={Col} controlId="born">
            <Form.Label>Doğum Tarihi</Form.Label>
            <Form.Control type="text" readOnly defaultValue={sessions.client.born}  />
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} controlId="username">
          <Form.Label>Kullanıcı Adı</Form.Label>
          <Form.Control readOnly type="text" placeholder="Kullanıcı Adınız" defaultValue={sessions.client.username} />
        </Form.Group>

        <Form.Group as={Col} controlId="email">
          <Form.Label>E-Posta</Form.Label>
          <Form.Control readOnly type="email" placeholder="isimsoyisim@adres.com" defaultValue={sessions.client.email} />
        </Form.Group>

        <Form.Row>
          <Form.Group as={Col} controlId="fullname">
            <Form.Label>Ad</Form.Label>
            <Form.Control readOnly type="text" placeholder="Adınız" defaultValue={sessions.client.fname} />
          </Form.Group>

          <Form.Group as={Col} controlId="gender">
            <Form.Label>Cinsiyet</Form.Label>
            <Form.Control readOnly type="text" placeholder="Adınız" value={gender === "m" ? 'Bay' : 'Bayan'} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="lastname">
            <Form.Label>Soyad</Form.Label>
            <Form.Control readOnly type="text" placeholder="Soyadınız" defaultValue={sessions.client.lname} />
          </Form.Group>

          <Form.Group as={Col} controlId="phone">
            <Form.Label>Cep Telefon</Form.Label>
            <Form.Control type="text" name="phone" onChange={handleChange('phone')} onClick={handleClick} placeholder="+90(123)123-45-67" defaultValue={sessions.client.phone} />
          </Form.Group>
        </Form.Row>

        <Form.Row>
          <Form.Group as={Col} controlId="country">
            <Form.Label>Ülke</Form.Label>
            <Form.Control as="select" value={values.coid === undefined ? sessions.client.coid : values.coid}  onChange={handleChange('coid')} onClick={handleClick}>

              {
                countries.map((countriesItem) => (

                  <option value={countriesItem.coid} name="coid" key={countriesItem.coid}  >{countriesItem.country}</option>

                ))
              }



            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="city">
            <Form.Label>Şehir</Form.Label>
            <Form.Control type="text" placeholder="Şehir" name="city" onChange={handleChange('city')} defaultValue={sessions.client.city} onClick={handleClick} />
          </Form.Group>
        </Form.Row>

        <Form.Group as={Col} controlId="timezones">
          <Form.Label>Saat Dilimi</Form.Label>
          <Form.Control as="select" value={values.tz === undefined ? sessions.client.tz : values.tz}  onChange={handleChange('tz')} onClick={handleClick}>

            {
              timezones.map((timezonesItem) => (

                <option value={timezonesItem.tzid} name="tz" key={timezonesItem.tzid}  >{timezonesItem.adj} {timezonesItem.name}</option>

              ))
            }



          </Form.Control>
        </Form.Group>


        <Form.Group as={Col} controlId="address">
          <Form.Label>Adres</Form.Label>
          <Form.Control as="textarea" rows={3} name="address" onChange={handleChange('address')} defaultValue={sessions.client.address} />
        </Form.Group>

        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>Teklifleri ve haberleri bana bildir</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="newsletterInfo"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>Dahili Mesajlar</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="messagesInfo"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>Push Bildirimleri</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="pushInfo"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>Telefon Görüşmesi</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="phoneInfo"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>E-Posta</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="emailInfo"
            />
          </Form.Group>
        </Form.Row>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label>SMS</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="smsInfo"
            />
          </Form.Group>
        </Form.Row>
        <div className="submitForm">
          <Button variant="primary" type="submit" className={hidden}>
            Güncelle
          </Button>
        </div>
        </Form>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Myaccount;
