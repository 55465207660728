import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const SportsContext = createContext();
const SportsContextProvider = (props) => {
const [sports, setSports] = useState([{
  cid:0,
  name:"default",
  ni:"icon.png",
  ic:"icon.png"
}]);


const refreshIdData = (pageId) => {
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/sportCategories.php?sid="+pageId,
  })
  .then(function (response) {
    //handle then
    setSports(response.data);
  },[])
  .catch(function (response) {
    //handle error
  });
};

useEffect(() => {
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/sportCategories.php",
  })
  .then(function (response) {
    //handle then
    setSports(response.data);
  })
  .catch(function (response) {
    //handle error

  });
}, []);


  return(
    <SportsContext.Provider value={{sports, refreshIdData}}>
      {props.children}
    </SportsContext.Provider>
  )
}
export default SportsContextProvider;
