import { useContext, useState } from 'react';
import { CampaignsContext } from 'Context/campaignsContext';
import Modal from 'react-bootstrap/Modal';
function Campaign() {
  const {campaignsPage} = useContext(CampaignsContext);
  const campaignItems = campaignsPage.[0].campaigns;
  const [show, setShow] = useState(false);
  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
      <div className="campaign">
        <Modal
        show={show}
        onHide={handleClose}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaignModal"
        >
          <Modal.Header closeButton>
            <Modal.Title>{name}</Modal.Title>
          </Modal.Header>
          <Modal.Body dangerouslySetInnerHTML={{ __html: content }}></Modal.Body>
        </Modal>
        {
          campaignItems.map((campaignItem, index) => (
            <div className="Items" key={index}>
              <img src={campaignItem.src} alt={campaignItem.name} onClick={() => {handleShow(); setName(campaignItem.name); setContent(campaignItem.content); }} />
            </div>
          ))

        }
      </div>
  );
}

export default Campaign;
