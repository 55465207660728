import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { CategoriesContext } from 'Context/categoriesContext';
import { SportsContext } from 'Context/sportsContext';
import { MatchsContext } from 'Context/matchsContext';
function Categories() {
  const {categories, refreshPageData} = useContext(CategoriesContext);
  const {refreshIdData} = useContext(SportsContext);
  const {refreshsId} = useContext(MatchsContext);
  const handleSubmit = (pageName, pageId) => {
    refreshPageData(pageName);
    refreshIdData(pageId);
    refreshsId(pageId);
  }
  return (
    <div className="topcategoriesMenu">
      <h3>Spor Bahisleri</h3>
      <ul>
      {
        categories.map((categorieItem, index) => (
          <li key={index}>
            <Link to="/prematch" onClick={() => handleSubmit(categorieItem.name, categorieItem.sid)}>
              <img src={categorieItem.ica} alt={categorieItem.name} />
            </Link>
          </li>
        ))
      }
      </ul>
    </div>
  );
}


export default Categories;
