import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import { useCookies } from 'react-cookie';

export const CountriesContext = createContext();
const CountriesContextProvider = (props) => {
  const [cookies] = useCookies([
    'ta',
    'malvi',
    'malvk',
    'sess'
  ], []);
  const [countries, setCountries] = useState([]);

  const refreshCountriesData = (ta, malvi, malvk, sess) => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/getCountries.php",
      headers: {
        "ta": ta,
        "malvi": malvi,
        "malvk": malvk,
        "sess": sess
      },
    })
    .then(function (response) {
      //handle then
      setCountries(response.data)
    },[])
    .catch(function (response) {
      //handle error
    });
  };


  useEffect(() => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/getCountries.php",
      headers: {
        "ta": cookies.ta,
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
        "sess": cookies.sess,
      },
    })
    .then(function (response) {
      //handle then
      setCountries(response.data);
    })
    .catch(function (response) {
      //handle error

    });
  }, [cookies.malvi, cookies.ta, cookies.sess, cookies.malvk]);



  return(
    <CountriesContext.Provider value={{countries, refreshCountriesData}}>
      {props.children}
    </CountriesContext.Provider>
  )
}
export default CountriesContextProvider;
