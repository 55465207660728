import { createContext, useState } from 'react';
import axios from 'axios';
export const LoginPageContext = createContext();
const LoginPageContextProvider = (props) => {
  const [userInfo, setUserInfo] = useState(
    {
      client:[{
        identify:0,
        born:"",
        username:"",
        email:"",
        fname:"",
        lname:"",
        docno:"",
        phone:"",
        country:"",
        city:"",
        address:"",
        lid:"",
      }],
      avsrvs:[{
        prematch:0,
        liveodds:0,
        casino:0,
        livecasino:0,
        virtsports:0,
        meteorgames:0,
      }],
      notifications:[{
        sms:0,
      }]
    }
  );

  const refreshUserData = (ta, malvi, malvk, sess) => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/userAPI.php",
      headers: {
        "ta": ta,
        "malvi": malvi,
        "malvk": malvk,
        "sess": sess
      },
    })
    .then(function (response) {
      //handle then
      setUserInfo(response.data)
    })
    .catch(function (response) {
      //handle error
    });
  };



  return(
    <LoginPageContext.Provider value={{userInfo, refreshUserData}}>
      {props.children}
    </LoginPageContext.Provider>
  )
}
export default LoginPageContextProvider;
