function Banner() {
  return (
      <div className="mainBanner">
        <div className="bannerColumn">
          <div className="bannerBig"><img src="https://d2gg9evh47fn9z.cloudfront.net/800px_COLOURBOX33473005.jpg" alt="Banner" /></div>
        </div>
        <div className="bannerColumn">
          <div className="bannerSmall"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZ8cOhibgj-GSSn-cQ_ZWibM999qPBXZfB9VmH32FEEUU157JAcn97IfPgLmMy5UkcGQ&usqp=CAU" alt="Banner" /></div>
          <div className="bannerSmall"><img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTgnjynof6gmDOtBEdsfFqVLOlnAN0rFfOpLBWBUlMGtWb2nNQOI7Bzrve3i12SDnpzq2c&usqp=CAU" alt="Banner" /></div>
        </div>
        <div className="clearfix"></div>
      </div>
  );
}


export default Banner;
