import './App.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import WebRouter from './webRouter';
import ConfigContextProvider from 'Context/configContext';
import LoginPageContextProvider from 'Context/loginContext';
import SessionsContextProvider from 'Context/sessionsContext';
import CountriesContextProvider from 'Context/countriesContext';
import Spinner from 'react-bootstrap/Spinner';
import { CookiesProvider } from 'react-cookie';
function App() {
  return (
    <ConfigContextProvider>
      <CookiesProvider>
        <SessionsContextProvider>
          <CountriesContextProvider>
            <LoginPageContextProvider>
                <div className="loaderBar">
                  <Spinner variant="primary" animation="grow" />
                </div>
                <WebRouter />
            </LoginPageContextProvider>
          </CountriesContextProvider>
        </SessionsContextProvider>
      </CookiesProvider>
    </ConfigContextProvider>
  );
}

export default App;
