import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import $ from "jquery";
import { useCookies } from 'react-cookie';

export const BonusContext = createContext();
const BonusContextProvider = (props) => {
  const [cookies] = useCookies([
    'malvi',
    'malvk',
  ], []);
  const [bonus, setBonus] = useState([]);

  const refreshBonusData = (malvi, malvk) => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/client/client_bonuses.php",
      headers: {
        "malvi": malvi,
        "malvk": malvk,
      },
    })
    .then(function (response) {
      //handle then
      setBonus(response.data)
    },[])
    .catch(function (response) {
      //handle error
    });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/client/client_bonuses.php",
      headers: {
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
      },
    })
    .then(function (response) {
      //handle then
      setBonus(response.data);
      $(".loaderBar").hide();
    },[])
    .catch(function (response) {
      //handle error

    });
  }, [cookies.malvi, cookies.malvk]);



  return(
    <BonusContext.Provider value={{bonus, refreshBonusData}}>
      {props.children}
    </BonusContext.Provider>
  )
}
export default BonusContextProvider;
