import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function ScrollFooter() {
  const {config} = useContext(ConfigContext);
  const footerMenu = config.[0].footermenu;
  const staticMenu = config.[0].avsrvs;
  const prematch = staticMenu[0].prematch;
  const liveodds = staticMenu[0].liveodds;
  const prematchMenu = () => {
      if (prematch) {
        return(
          <Link to="/prematch"><img src="/images/icons/sport.png" alt="Sport" width="24" /><p>Sport</p></Link>
        );
      } else {
        return;
      }
  }
  const liveoddsMenu = () => {
      if (liveodds) {
        return(
          <Link to="/live"><img src="/images/icons/live.png" alt="Live" width="24" /><p>Live</p></Link>
        );
      } else {
        return;
      }
  }
  return (
    <footer className="mainFooter">
      <div className="scrollArea">
        <ul className="flexMenu">
          <li><Link to="/"><img src="/images/icons/home.png" alt="Home" width="24" /><p>Home</p></Link></li>
          <li>{prematchMenu()}</li>
          <li>{liveoddsMenu()}</li>
          <li><Link to="/coupons"><img src="/images/icons/coupon.png" alt="Coupon" width="24" /><p>Coupons</p></Link></li>
          {
            footerMenu.map((menuItem, index) => (
              <li><Link key={menuItem.name} to={menuItem.src}><img src={menuItem.menuIcon} width="24" alt={menuItem.name} /><p>{menuItem.name}</p></Link></li>
            ))
          }
        </ul>
      </div>
      <div className="supportPosition">
        <Link to="/support"><img src="/images/icons/support.png" alt="Support" width="24" /><p>Support</p></Link>
      </div>
      <div className="clearfix"></div>
    </footer>
  );
}


export default ScrollFooter;
