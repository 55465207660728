import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import Icofont from 'react-icofont';

function Newsletter() {
  return (
    <div className="mainNewsletter">
    <h3>Newsletter</h3>
    <p>Mail adresinizi bize yazarak Alavara Bet bültenine katılarak tüm gelişmelerden haberdar olabilirsiniz.</p>
    <InputGroup className="mb-3">
      <FormControl
        placeholder="adınız@soyadınız.com"
        aria-label="adınız@soyadınız.com"
        aria-describedby="basic-addon2"
      />
      <InputGroup.Append>
        <InputGroup.Text id="basic-addon2"><Icofont icon="envelope-open"/></InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
    </div>
  );
}


export default Newsletter;
