import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
function Withdraw() {
  return (
    <Tabs defaultActiveKey="udeposit" id="userTabs" className="userTabs">
    <Tab eventKey="udeposit" title="Para Çekim">
      <Tabs defaultActiveKey="quickqr" id="paymentMode" className="paymentMode">
        <Tab eventKey="quickqr" title="Anında QR">
          <div className="paymentTabContent">
            <p style={{marginBottom:'0'}}>Hesap numaranızı yazdıktan sonra aşağıdaki butona tıklayarak çekim talebinizi oluşturabilirsiniz.</p>
            <Form className="withdrawForm">
              <Row>
                <Col>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Label>Hesap No:</Form.Label>
                    <Form.Control size="sm" placeholder="First name" />
                  </Form.Group>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Label>Miktar:</Form.Label>
                    <Form.Control size="sm" placeholder="First name" />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formGroupEmail">
                    <Form.Label>Not:</Form.Label>
                    <Form.Control size="sm" as="textarea" rows={4} />
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Button variant="primary">Para Çek</Button>
          </div>
        </Tab>
        <Tab eventKey="quickcreditcard" title="Anında Kredi Kartı">

        </Tab>
        <Tab eventKey="quickeft" title="Anında Havale/EFT">

        </Tab>
        <Tab eventKey="quickpapara" title="Anında PaPara">

        </Tab>
        <Tab eventKey="payfix" title="PayFix">

        </Tab>
        <Tab eventKey="favexs" title="Favexs Pro">

        </Tab>
        <Tab eventKey="cepbank" title="CepBank">

        </Tab>
        <Tab eventKey="qrcode" title="QR Kod">

        </Tab>
        <Tab eventKey="parapara" title="PaPara">

        </Tab>
        <Tab eventKey="eft" title="Havale/EFT">

        </Tab>
        <Tab eventKey="jeton" title="Jeton">

        </Tab>
        <Tab eventKey="coin" title="Coin">

        </Tab>
      </Tabs>
    </Tab>
    <Tab eventKey="udepositInfo" title="Para Çekim Durumu">
      <div className="depositStatus">
        <p><strong>Çekim Biçimi:</strong> PaPara</p>
        <p><strong>Çekilen Tutar:</strong> 1000TL</p>
        <p><strong>Çekim Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Beklemede <Icofont icon="icofont-check-circled" className="pending" /></p>
      </div>
      <div className="depositStatus">
        <p><strong>Çekim Biçimi:</strong> PaPara</p>
        <p><strong>Çekilen Tutar:</strong> 1000TL</p>
        <p><strong>Çekim Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Onaylandı <Icofont icon="icofont-check-circled" className="success" /></p>
      </div>
      <div className="depositStatus">
        <p><strong>Çekim Biçimi:</strong> PaPara</p>
        <p><strong>Çekilen Tutar:</strong> 1000TL</p>
        <p><strong>Çekim Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Onaylanmadı <Icofont icon="icofont-check-circled" className="danger" /></p>
      </div>
    </Tab>
    </Tabs>
  );
}
export default Withdraw;
