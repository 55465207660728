import { Link } from "react-router-dom";
import Icofont from 'react-icofont';
import $ from "jquery";
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function SidebarMenu() {
  function hideSidebar(){
    $( ".SidebarMenu" ).animate({
      left: "-235px",
    }, 1500, function() {
    });
  };
  const {config} = useContext(ConfigContext);
  const mainMenu = config.mainmenu;
  const version = config.version;
  const clientLogo = config.sitelogo;
  const staticMenu = config.avsrvs;
  const prematch = staticMenu.prematch;
  const liveodds = staticMenu.liveodds;
  const casino = staticMenu.casino;
  const livecasino = staticMenu.livecasino;
  const virtsports = staticMenu.virtsports;
  const meteorgames = staticMenu.meteorgames;
  const prematchMenu = () => {
      if (prematch) {
        return(
          <li>
            <Link to="/prematch"><img src="/images/icons/soccer-ball.png" alt="Home" />Spor Bahisleri</Link>
          </li>
        );
      } else {
        return;
      }
  }
  const liveoddsMenu = () => {
      if (liveodds) {
        return(
          <li>
            <Link to="/live"><img src="/images/icons/betting.png" alt="Home" />Canlı Bahisler</Link>
          </li>
        );
      } else {
        return;
      }
  }
  const casinoMenu = () => {
      if (casino) {
        return(
          <li>
            <Link to="/casino"><img src="/images/icons/spade.png" alt="Home" />Casino</Link>
          </li>
        );
      } else {
        return;
      }
  }
  const livecasinoMenu = () => {
      if (livecasino) {
        return(
          <li>
            <Link to="/live-casino"><img src="/images/icons/spade.png" alt="Home" />Live Casino</Link>
          </li>
        );
      } else {
        return;
      }
  }
  const virtsportsMenu = () => {
      if (virtsports) {
        return(
          <li>
            <Link to="/virtsports"><img src="/images/icons/dices.png" alt="Home" />Sanal Oyunlar</Link>
          </li>
        );
      } else {
        return;
      }
  }
  const meteorgamesMenu = () => {
      if (meteorgames) {
        return(
          <li>
            <Link to="/meteor-games"><img src="/images/icons/dices.png" alt="Home" />Meteor Games</Link>
          </li>
        );
      } else {
        return;
      }
  }

  return (
    <div className="SidebarMenu">
      <h2 className="brand">
        <img src={clientLogo} alt="Logo" />
        <Icofont icon="close-squared-alt" onClick={hideSidebar} />
      </h2>
      <ul>
        {prematchMenu()}
        {liveoddsMenu()}
        {casinoMenu()}
        {livecasinoMenu()}
        {virtsportsMenu()}
        {meteorgamesMenu()}
        {
          mainMenu.map((menuItem, index) => (
            <li key={menuItem.name}>
              <Link to={menuItem.src}><img src={menuItem.menuIcon} alt={menuItem.name} />{menuItem.name}</Link>
            </li>
          ))
        }
      </ul>
      <div className="version">Version: {version}</div>
    </div>
  );
}

export default SidebarMenu;
