import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function RegisterPage() {
  const {config} = useContext(ConfigContext);
  const clientLogo = config.sitelogo;
  return (
    <div className="mainRegister">
    <h5><img src={clientLogo} alt="Brand" /></h5>
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="example@example.com" />
        <Form.Text className="text-muted">
          Wrong Formatted!
        </Form.Text>
      </Form.Group>

      <Form.Group controlId="formBasicNameSurname">
        <Form.Label>Name / Surname</Form.Label>
        <Form.Control type="text" placeholder="Name and Surname" />
      </Form.Group>

      <Form.Group controlId="formBasicUsername">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" placeholder="Username" />
      </Form.Group>

      <Form.Group controlId="formBasicPhone">
        <Form.Label>Phone</Form.Label>
        <Form.Control type="number" placeholder="phone" />
      </Form.Group>

      <Form.Group controlId="formBasicEmail">
        <Form.Label>Birth Date</Form.Label>
        <Form.Control type="date" placeholder="Birth Date" />
      </Form.Group>

      <Form.Group controlId="formBasicAdress">
        <Form.Label>Adress</Form.Label>
        <Form.Control  as="textarea" rows={3}  placeholder="Adress" />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" placeholder="*********" />
      </Form.Group>

      <Form.Group controlId="formBasicPasswordAgain">
        <Form.Label>Password Again</Form.Label>
        <Form.Control type="password" placeholder="*********" />
      </Form.Group>
    </Form>
    <Form.Group controlId="formBasicCheckbox" className="checkRobot">
      <Form.Check type="checkbox" label="I'm Not Robot!" />
    </Form.Group>
    <Button variant="primary" type="submit">
      Register!
    </Button>
    <div className="newRegister">
      <span>Do yo have account?</span> <Link to="/login">Login</Link>
    </div>
    </div>
  );
}

export default RegisterPage;
