import { useState, useRef } from 'react';
import Icofont from 'react-icofont';
import { Link } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
function Approvedaccount() {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="approvedAccountPage">
      <p className="approvedContent">
        <strong>‘’Belge Türünü Seçiniz’’</strong> bölümünden, yüklemek istediğiniz belge türünü (pasaport, sürücü belgesi, kimlik belgesi) seçin, <strong>‘’Yükle’’</strong> butonuna tıklayarak belgenizi yükleyin ve <strong>‘’Doğrula’’</strong> butonuna tıklayın. Yüklediğiniz belge onaylandığında durum sekmesinde <strong>‘’Kabul Edildi’’</strong> ibaresi görünecektir.
      </p>
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Belge Türü</Form.Label>
            <Form.Control as="select" size="sm">
              <option>Bonus</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Yükle</Form.Label>
            <Form.File
              id="custom-file"
              label="Dosyayı Seçin"
              data-browse="Seç"
              custom
            />
          </Form.Group>
        </Form.Row>
        <p className="submitForm">
          <Button variant="primary">Doğrula</Button>
        </p>
      </Form>
      <div className="approvedList">
        <p><strong>Belge Türü:</strong> Ehliyet</p>
        <p><strong>Belge Adı:</strong> Ehliyetim</p>
        <p><strong>Yükleme Tarihi:</strong> 30/06/2021 - 10:30</p>
        <p><strong>Durum:</strong> Doğrulanmadı <Icofont icon="icofont-check-circled" className="danger" /></p>
        <Button className="infos" ref={target} onClick={() => setShow(!show)} ><Icofont icon="icofont-info-circle"  /></Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Kimlik bilgileriniz geçersizdir.
            </Tooltip>
          )}
        </Overlay>
      </div>
      <div className="approvedList">
        <p><strong>Belge Türü:</strong> Ehliyet</p>
        <p><strong>Belge Adı:</strong> Ehliyetim</p>
        <p><strong>Yükleme Tarihi:</strong> 30/06/2021 - 10:30</p>
        <p><strong>Durum:</strong> Beklemede <Icofont icon="icofont-check-circled" className="pending" /></p>
        <Button className="infos" ref={target} onClick={() => setShow(!show)} ><Icofont icon="icofont-info-circle"  /></Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              İşlem Onay Bekliyor
            </Tooltip>
          )}
        </Overlay>
      </div>
      <div className="approvedList">
        <p><strong>Belge Türü:</strong> Ehliyet</p>
        <p><strong>Belge Adı:</strong> Ehliyetim</p>
        <p><strong>Yükleme Tarihi:</strong> 30/06/2021 - 10:30</p>
        <p><strong>Durum:</strong> Doğrulandı <Icofont icon="icofont-check-circled" className="success" /></p>
        <Button className="infos" ref={target} onClick={() => setShow(!show)} ><Icofont icon="icofont-info-circle"  /></Button>
        <Overlay target={target.current} show={show} placement="bottom">
          {(props) => (
            <Tooltip id="overlay-example" {...props}>
              Bilgileriniz başarıyla doğrulandı
            </Tooltip>
          )}
        </Overlay>
      </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Approvedaccount;
