import React, {useState, useRef, useContext} from 'react';
import Carousel from 'react-bootstrap/Carousel';
import { LandingPageContext } from 'Context/landingpageContext';
function Slider() {
  const {landingPage} = useContext(LandingPageContext);
  const sliderItem = landingPage.hpslides;
  const [index, setIndex] = useState(0);
  const slideRef = useRef(null);
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    slideRef.current.focus();
  };

  return (
    <div className="mainSlider">
    <Carousel activeIndex={index} onSelect={handleSelect}>
      {
        sliderItem.map((slideItem, index) => (
          <Carousel.Item ref={slideRef} key={index}>
            <img
              className="d-block w-100"
              src={slideItem.src}
              alt="First slide"
              height="150"
            />
          </Carousel.Item>
        ))
      }
    </Carousel>
    </div>
  );
}

export default Slider;
