import { Link } from "react-router-dom";
import Icofont from 'react-icofont';
import { useContext } from 'react';
import { SessionsContext } from 'Context/sessionsContext';

function LoginLogout() {
  function UserLoggedIn() {
  return (
    <div className="UsersButton">
      <Icofont icon="search-2"/>
      <Link to="/myaccount">
        <Icofont icon="ui-user"/>
      </Link>
      <Icofont icon="world"/>
    </div>
  );
  }

  function UserRegister() {
    return (
      <div className="UsersButton">
        <Link to="/login">Login</Link>
        <Link to="/register">Register</Link>
      </div>
    );
  }
  function LoginArea() {
    const {sessions} = useContext(SessionsContext);
    if (sessions.clid > 0) {
      return <UserLoggedIn />;
    }
      return <UserRegister />;
    }
  return (
    <LoginArea isLoggedIn={0} />
  );
}


export default LoginLogout;
