import Icofont from 'react-icofont';
import { Link, useHistory } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
import Table from 'react-bootstrap/Table';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import { BonusContext } from 'Context/bonusContext';
import { useCookies } from 'react-cookie';

function Mybonus() {
  let history = useHistory();
  const [cookies] = useCookies([
    'malvi',
  ], []);
  if(cookies.malvi === undefined) {
    history.push('/login')
  }
  const {bonus} = useContext(BonusContext);
  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="mybetsPage">
      <Form>
        <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Bonus Çeşidi</Form.Label>
            <Form.Control as="select" size="sm">
              <option>Bonus</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Tarih</Form.Label>
            <Form.Control as="select" size="sm">
              <option>--/--/----</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>
        <p className="submitBtn">
          <Button variant="primary">Göster</Button>
        </p>
      </Form>

      <div className="mybonusPage">
      <Table responsive striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Tarih/Saat</th>
            <th>Bonus Türü</th>
            <th>Miktar</th>
          </tr>
        </thead>
        <tbody>
        {
          bonus.map((bonusItem, index) => (
            <tr key={index}>
              <td>{bonusItem.receivedon}</td>
              <td>{bonusItem.name}</td>
              <td>{bonusItem.amount}</td>
            </tr>

          ))
        }
        </tbody>
        </Table>
      </div>

      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Mybonus;
