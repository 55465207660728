import { Link } from "react-router-dom";
import { useContext } from 'react';
import { MilesContext } from 'Context/milesContext';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
function Mile() {
  const {milesPage} = useContext(MilesContext);
  const milesItems = milesPage.[0].miles;
  return (
      <div className="mainMile">
        <div className="milesHeader">
          <div className="brand">ALAVARA MILE</div>
          <div className="brandMenu">
            <Link to="/">MILE Nedir?</Link>
            <Link to="/">Nasıl Toplanır?</Link>
            <Link to="/">İletişim?</Link>
          </div>
          <div className="clearfix"></div>
          <p>Binlerce puan topla, onlarca fırsatı kap!</p>
          <hr/>
          <p>Yatırım yaptıkça, oynadıkça mil kazan, kazandığın milleri AlavaraMile sayesinde fırsata çevir. Onlarca güncellenen fırsattan beğendiğini satın al.</p>
          <Link to="/" className="primary-button">Fırsatları İncele</Link>
        </div>
        <div className="milesContent">
          <div className="milesSelect">
            <DropdownButton id="dropdown-basic-button" title="Tümü">
              <Dropdown.Item href="#/action-1">Seçenek 1</Dropdown.Item>
              <Dropdown.Item href="#/action-2">Seçenek 2</Dropdown.Item>
              <Dropdown.Item href="#/action-3">Seçenek 3</Dropdown.Item>
            </DropdownButton>
          </div>
          <div className="Items">
            {
              milesItems.map((mileItem, index) => (
                <div className="Item" key={mileItem.id}>
                  <div className="title">{mileItem.title}</div>
                  <div className="image">
                    <img src={mileItem.src} alt={mileItem.title} width="120" />
                  </div>
                  <div className="footer">
                    <div className="pointMile">
                      <p><strong>{mileItem.mile}</strong></p>
                      <p>{mileItem.brand}Miles</p>
                    </div>
                    <div className="pointButton">
                      <Link to="/" className="primary-button">Satın Al</Link>
                    </div>
                  </div>
                </div>
              ))
            }
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
  );
}

export default Mile;
