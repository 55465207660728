import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import ListGroup from 'react-bootstrap/ListGroup';
import Badge from 'react-bootstrap/Badge';
import { CategoriesContext } from 'Context/categoriesContext';
import { SportsContext } from 'Context/sportsContext';
import { MatchsContext } from 'Context/matchsContext';
function DefaultCategories() {
  const {pageName} = useContext(CategoriesContext);
  const {sports} = useContext(SportsContext);
  const {refreshcId} = useContext(MatchsContext);
  const handleSubmit = (cid) => {
    refreshcId(cid);
  }
  return(
    <div className="mainCategories">
      <h3>{pageName.pageName}</h3>
      <ListGroup as="ul">
      {
        sports.map((sportsItem, index) => (

          <ListGroup.Item as="li" key={index}>
            <Link to="/sports" onClick={() => handleSubmit(sportsItem.cid)}>
              <img src={sportsItem.ic} alt={sportsItem.name} />
              <span>{sportsItem.name}</span>
              <Badge variant="info">{sportsItem.ni}</Badge>
            </Link>
          </ListGroup.Item>

        ))
      }
      </ListGroup>
    </div>
  );
}
export default DefaultCategories;
