import { createContext, useState, useEffect } from 'react';
import axios from 'axios';

export const CategoriesContext = createContext();
const CategoriesContextProvider = (props) => {
const [categories, setCategories] = useState([{
  sid:0,
  name:"default",
  ica:"icon.png",
  icb:"icon.png",
  ni:0,
  priority:0
}]);
const [pageName, setPagename] = useState({
  pageName:"",
});

const refreshPageData = (pageName) => {
  setPagename({pageName:pageName})
};

useEffect(() => {
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/getSports.php",
  })
  .then(function (response) {
    //handle then
    setCategories(response.data);
  })
  .catch(function (response) {
    //handle error

  });
}, []);


  return(
    <CategoriesContext.Provider value={{categories, pageName, refreshPageData}}>
      {props.children}
    </CategoriesContext.Provider>
  )
}
export default CategoriesContextProvider;
