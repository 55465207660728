import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import $ from "jquery";
import { useCookies } from 'react-cookie';

export const SessionsContext = createContext();
const SessionsContextProvider = (props) => {
  const [cookies] = useCookies([
    'ta',
    'malvi',
    'malvk',
    'sess'
  ], []);
  const [sessions, setSessions] = useState({
    clid:0,
    client:[{
      fname:"",
      cardid:0,
      gender:0
    }]
  });

  const refreshSessionsData = (ta, malvi, malvk, sess) => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/userAPI.php",
      headers: {
        "ta": ta,
        "malvi": malvi,
        "malvk": malvk,
        "sess": sess
      },
    })
    .then(function (response) {
      //handle then
      setSessions(response.data)
    },[])
    .catch(function (response) {
      //handle error
    });
  };

  const updateSessionsData = (phone, city, address, coid, tz) => {

    const bodyFormData = new FormData();
    bodyFormData.append('phone', phone);
    bodyFormData.append('city', city);
    bodyFormData.append('address', address);
    bodyFormData.append('coid', coid);
    bodyFormData.append('tz', tz);
    axios({
      method: "post",
      url: "https://betmob.cincev.com/api/postUserAPI.php",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "ta": cookies.ta,
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
        "sess": cookies.sess,
      },
    })
    .then(function (response) {
      //handle then
      $(".loaderBar").hide();
    },[])
    .catch(function (response) {
      //handle cath
    });
  }

  useEffect(() => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/userAPI.php",
      headers: {
        "ta": cookies.ta,
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
        "sess": cookies.sess,
      },
    })
    .then(function (response) {
      //handle then
      setSessions(response.data);
      $(".loaderBar").hide();
    },[])
    .catch(function (response) {
      //handle error

    });
  }, [cookies.malvi, cookies.ta, cookies.sess, cookies.malvk]);



  return(
    <SessionsContext.Provider value={{sessions, refreshSessionsData, updateSessionsData}}>
      {props.children}
    </SessionsContext.Provider>
  )
}
export default SessionsContextProvider;
