import Icofont from 'react-icofont';
import { Link } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import MainUserTabs from 'Component/globals/usermain-tabs';
function Balances() {
  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="balancesPage">
        <MainUserTabs />
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Balances;
