import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Icofont from 'react-icofont';
function PaymentHistory() {
  return (
    <>
    <Form className="paymentHistoryChecks">
      <Form.Row>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>İşlem Tipi</Form.Label>
          <Form.Control as="select" defaultValue="Choose...">
            <option>Hepsi</option>
            <option>...</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Kategori</Form.Label>
          <Form.Control as="select" defaultValue="Choose...">
            <option>Ana</option>
            <option>...</option>
          </Form.Control>
        </Form.Group>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Label>Tarih</Form.Label>
          <Form.Control as="select" defaultValue="Choose...">
            <option>--/--/----</option>
            <option>...</option>
          </Form.Control>
        </Form.Group>
      </Form.Row>
    </Form>
    <div className="paymentStatus">
      <p><strong>ID:</strong> 1235484548</p>
      <p><strong>Tür:</strong> Para Yatırma (Maksi)</p>
      <p><strong>Miktar:</strong> 1000TL</p>
      <p><strong>Tarih:</strong> 14/06/2021 - 12:16</p>
      <p><strong>Durum:</strong> Onaylandı <Icofont icon="icofont-check-circled" className="success" /></p>
    </div>
    <div className="paymentStatus">
      <p><strong>ID:</strong> 1235484548</p>
      <p><strong>Tür:</strong> Para Yatırma (Maksi)</p>
      <p><strong>Miktar:</strong> 1000TL</p>
      <p><strong>Tarih:</strong> 14/06/2021 - 12:16</p>
      <p><strong>Durum:</strong> Onaylanmadı <Icofont icon="icofont-check-circled" className="danger" /></p>
    </div>
    <div className="paymentStatus">
      <p><strong>ID:</strong> 1235484548</p>
      <p><strong>Tür:</strong> Para Yatırma (Maksi)</p>
      <p><strong>Miktar:</strong> 1000TL</p>
      <p><strong>Tarih:</strong> 14/06/2021 - 12:16</p>
      <p><strong>Durum:</strong> Beklemede <Icofont icon="icofont-check-circled" className="pending" /></p>
    </div>
    </>
  );
}
export default PaymentHistory;
