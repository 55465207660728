import { Link } from "react-router-dom";
import Icofont from 'react-icofont';
import LoginLogout from 'Module/login-logout';
import SidebarMenu from 'Module/sidebar-menu';
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';
import $ from "jquery";

function DefaultHeader() {
  const {config} = useContext(ConfigContext);
  const clientLogo = config.sitelogo;
  function showSidebar(){
    $( ".SidebarMenu" ).animate({
      left: "0",
    }, 1500, function() {
    });
  }
  return (
    <header className="mainHeader">
      <div className="logo">
        <Icofont icon="navigation-menu" className="menuNavigate" onClick={showSidebar} />
        <SidebarMenu/>
          <Link to="/"><img src={clientLogo} alt="Logo" /></Link>
      </div>
      <div className="login">
        <LoginLogout />
      </div>
    </header>
  );
}

export default DefaultHeader;
