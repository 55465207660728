import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';
import { Link } from "react-router-dom";
function FooterMenu() {
  const {config} = useContext(ConfigContext);
  const clientName = config.sitename;
  return (
    <div className="mainFooterMenu">
      <div className="menuColumn">
        <h5 className="footerBrand">{clientName}</h5>
        <ul>
          <li>
            <Link to="/">Hakkımızda</Link>
          </li>
          <li>
            <Link to="/">Takip Et Kazan</Link>
          </li>
          <li>
            <Link to="/">Canlı Destek</Link>
          </li>
          <li>
            <Link to="/">İletişim</Link>
          </li>
        </ul>
      </div>
      <div className="menuColumn">
        <h5>Yardım</h5>
        <ul>
          <li>
            <Link to="/">Bahis Sonuçları</Link>
          </li>
          <li>
            <Link to="/">Maç Takvimi</Link>
          </li>
          <li>
            <Link to="/">S.S.S</Link>
          </li>
        </ul>
      </div>
      <div className="menuColumn">
        <h5>Kampanyalar</h5>
        <ul>
          <li>
            <Link to="/">1000TL Bonus</Link>
          </li>
          <li>
            <Link to="/">%5 Nakit İade</Link>
          </li>
          <li>
            <Link to="/">Kayıp Bonusu</Link>
          </li>
        </ul>
      </div>
      <div className="menuColumn">
        <h5>Ödüller</h5>
        <ul>
          <li>
            <Link to="/">Ödüller</Link>
          </li>
          <li>
            <Link to="/">Çekilişer</Link>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default FooterMenu;
