import { useState, useContext } from 'react';
import Icofont from 'react-icofont';
import { Link, useHistory } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useCookies } from 'react-cookie';
import { SessionsContext } from 'Context/sessionsContext';
import $ from "jquery";
import axios from 'axios';

function Changepassword() {
  let history = useHistory();
  const [cookies] = useCookies([
    'malvi',
  ], []);
  if(cookies.malvi === undefined) {
    history.push('/login')
  }
  const {sessions} = useContext(SessionsContext);
  const [values, setValues] = useState({
        password: "",
        password2: "",
        tz: "",
  });
  const handleChange = name => e => {
    if(values.password === "" || values.password2 === "" || values.tz === "" ) {
        setValues({password: values.password, password2: values.password2, tz:sessions.client.tz})
    }
    else {
      setValues({ ...values, [name]: e.target.value });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    $(".loaderBar").show();
    const bodyFormData = new FormData();
    bodyFormData.append('password', values.password);
    bodyFormData.append('password2', values.password2);
    bodyFormData.append('timezone', values.tz);
    axios({
      method: "post",
      url: "https://betmob.cincev.com/api/client/updateprofile.php",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
      },
    })
    .then(function (response) {
      //handle then
      $(".loaderBar").hide();
      console.log(response);
    },[])
    .catch(function (response) {
      //handle cath
    });

  }
  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="myaccountPage">
      <Form onSubmit={handleSubmit}>

        <Form.Group as={Col} controlId="password">
          <Form.Label>Yeni Şifre</Form.Label>
          <Form.Control type="password" name="password" placeholder="*********" onChange={handleChange('password')} />
        </Form.Group>

        <Form.Group as={Col} controlId="password2">
          <Form.Label>Şifreyi Doğrula</Form.Label>
          <Form.Control type="password" name="password2" placeholder="*********" onChange={handleChange('password2')} />
        </Form.Group>

        <div className="submitForm">
          <Button variant="primary" type="submit">
            Güncelle
          </Button>
        </div>
        </Form>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Changepassword;
