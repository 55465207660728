import React, {useContext} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import Accordion from 'react-bootstrap/Accordion';
import Icofont from 'react-icofont';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MatchDetailContext } from 'Context/matchdetailContext';

function DefaultSportsDetails() {
  const {matchDetail} = useContext(MatchDetailContext);
  return(
    <div>
      <div className="preWidget">
        <div className="homeTeam">
          <div className="homeIcon">
            <img src="/images/icons/homeTeam.png" alt="Home Team" />
          </div>
          <div className="homeText">{matchDetail.event.ma.c1}</div>
          <div className="clearfix"></div>
        </div>
        <div className="scoresInfo">
          <span className="score">- : -</span>
          <br/>
          <span className="date">{matchDetail.event.ma.day} - {matchDetail.event.ma.hour}</span>
        </div>
        <div className="awayTeam">
          <div className="awayText">{matchDetail.event.ma.c2}</div>
          <div className="awayIcon">
            <img src="/images/icons/awayTeam.png" alt="Away Team" />
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="clearfix"></div>
      </div>
      <div className="mainGetSportsDetails">
      {matchDetail.event.ma.length === 0 ?
        <div className="text-center errorPage">
          <div>Something went wrong!</div>
          <Link className="btn btn-primary " to="/">Go To HomePage</Link>
        </div>
        :
        <Tabs defaultActiveKey="1" id="getSportsDetail">
          {matchDetail.event.mo.map((tabItem, index) => (
              <Tab eventKey={tabItem.idx} title={tabItem.name} key={index}>
                <Accordion defaultActiveKey="20ec76da">
                  {tabItem.markets.map((marketsItem,index) => (
                    <div key={index}>
                    <Accordion.Toggle eventKey={marketsItem.mk}>
                      <span>{marketsItem.nm}</span>
                      <Icofont icon="icofont-caret-down"/>
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={marketsItem.mk}>
                      {marketsItem.odds.length > 3 ?
                      <div className="colscontent">
                        <Row>
                          {marketsItem.odds.map((oddsItem, index) => (
                              <Col key={index} xs={4}>
                                <Button className="detailOdds">
                                  <Row>
                                    <Col>{oddsItem.nm}</Col>
                                    <Col>{oddsItem.val}</Col>
                                  </Row>
                                </Button>
                              </Col>
                          ))
                          }
                        </Row>
                      </div>
                        :
                        <div className="colscontent">
                          <Row>
                            {marketsItem.odds.map((oddsItem, index) => (
                                <Col key={index}>
                                  <Button className="detailOdds">
                                    <Row>
                                      <Col>{oddsItem.nm}</Col>
                                      <Col>{oddsItem.val}</Col>
                                    </Row>
                                  </Button>
                                </Col>
                            ))
                            }
                          </Row>
                        </div>
                      }
                    </Accordion.Collapse>
                    </div>
                  ))
                  }
                </Accordion>
              </Tab>
          ))
          }
        </Tabs>
      }
      </div>
  </div>
  );
}
export default DefaultSportsDetails;
