import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
// import $ from "jquery";

export const MatchsContext = createContext();
const MatchsContextProvider = (props) => {
const [matchs, setMatchs] = useState([{
  tm:0,
  status:0,
  msg:"",
  selmarket:51,
  events:[{
    status:0,
    ma:{
      path:"",
    },
    mo:[{
      nm:"",
      odds:[{
        nm:"",
      }]
    }]
  }],
  markets:[{
    status:0,
  }],
  topmarkets:[{
      status:0,
  }],
  tourn:[{
      status:0,
  }],
}]);

const [matchsId, setMatchsId] = useState({
  sid:"",
  cid:"",
  tid:"",
  sellmarket:"",
});
const refreshsId = (sid) => {
    setMatchsId({...matchsId, sid:sid});
};
const refreshcId = (cid) => {
    setMatchsId({...matchsId, cid:cid});
};

const refreshsellmarket = (key) => {
    setMatchsId({...matchsId, sellmarket:key});
};

useEffect(() => {
// $(".loaderBar").show(); *
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/getFixtures.php?lang=en&sid="+matchsId.sid+"&cid="+matchsId.cid+"&tz=180&selmarket="+matchsId.sellmarket+"&page=100&period=1",
  })
  .then(function (response) {
    //handle then
    setMatchs(response.data);
//  $(".loaderBar").hide();
  })
  .catch(function (response) {
    //handle error

  });
}, [matchsId.sid, matchsId.tid, matchsId.cid, matchsId.sellmarket]);


  return(
    <MatchsContext.Provider value={{matchs, refreshsId, refreshcId, refreshsellmarket}}>
      {props.children}
    </MatchsContext.Provider>
  )
}
export default MatchsContextProvider;
