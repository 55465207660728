import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import $ from "jquery";

export const LandingPageContext = createContext();
const LandingPageContextProvider = (props) => {

  const [landingPage, setLandingPage] = useState(
    {
      hpslides:[
        { src:"/", linkto:"/"},
      ]
    }
  );
  useEffect(() => {
    axios.get("https://betmob.cincev.com/api/landingPage.php")
      .then((response) => {
        setLandingPage(response.data); // update your state
        $(".loaderBar").hide();
      })
      .catch((error) => {
        // handle errors
      });
  }, []);
  return(
    <LandingPageContext.Provider value={{landingPage}}>
      {props.children}
    </LandingPageContext.Provider>
  )
}
export default LandingPageContextProvider;
