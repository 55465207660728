import Header from 'Component/headers/header';
import Pagefit from 'Component/globals/pagefit';
import Footer from 'Component/footers/footer';
import DefaultSportsDetails from 'Component/sport-details/default';
function MatchDetail() {
  return (
    <div className="pageMatchDetail">
      <Header/>
      <DefaultSportsDetails/>
      <Pagefit/>
      <Footer/>
    </div>
  );
}

export default MatchDetail;
