import React from 'react';
import Header from 'Component/headers/header';
import Newsletter from 'Component/newsletters/newsletter';
import Pagefit from 'Component/globals/pagefit';
import FooterMenu from 'Component/footers/footerMenu';
import Footer from 'Component/footers/footer';
import Campaign from 'Component/globals/campaign';

function Campaigns() {
  return(
    <React.Fragment>
    <Header/>
    <Campaign/>
    <Newsletter/>
    <FooterMenu/>
    <Pagefit/>
    <Footer/>
    </React.Fragment>
  );
}
export default Campaigns;
