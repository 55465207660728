import React from 'react';
class Announcement extends React.Component {
  render(){
  return (
      <div className="announcement">
        <p>{this.props.content}</p>
      </div>
  )}
}

export default Announcement;
