import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Deposit from 'Component/user-components/deposit';
import Withdraw from 'Component/user-components/withdraw';
import PaymentHistory from 'Component/user-components/payment-history';
function MainUserTabs() {
  return (
    <Tabs defaultActiveKey="deposit" id="uncontrolled-tab-example">
    <Tab eventKey="deposit" title="Yatır">
      <Deposit/>
    </Tab>
    <Tab eventKey="withdraw" title="Çek">
      <Withdraw/>
    </Tab>
    <Tab eventKey="history" title="Geçmiş">
      <PaymentHistory/>
    </Tab>
    </Tabs>
  );
}
export default MainUserTabs;
