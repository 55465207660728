import { Link } from "react-router-dom";
import Icofont from 'react-icofont';
function UserMenu() {
  const urlAdress = window.location.pathname;
  return (
    <div className="UserMenu">
      <ul>
        <li>
          <Link key="balances" id="balances" className={urlAdress === '/balances' ? 'active' : ''} to="/balances">Bakiye</Link>
        </li>
        <li>
          <Link key="bettings" id="mybets" className={urlAdress === '/mybets' ? 'active' : ''} to="/mybets">Bahislerim</Link>
        </li>
        <li>
          <Link key="bonus" id="bonus" className={urlAdress === '/mybonus' ? 'active' : ''} to="/mybonus">Bonuslarım</Link>
        </li>
        <li>
          <Link key="account" id="account" className={urlAdress === '/myaccount' ? 'active' : ''} to="/myaccount">Kişisel Bilgiler</Link>
        </li>
        <li>
          <Link key="chngpsswrd" id="chgnpsswrd" className={urlAdress === '/change-password' ? 'active' : ''} to="/change-password">Şifre Değiştir</Link>
        </li>
        <li>
          <Link key="approved" id="approved" className={urlAdress === '/approved-account' ? 'active' : ''} to="/approved-account">Hesabı Onayla</Link>
        </li>
        <li className="twofactor">
          <Link key="twofactor" id="twofactor" className={urlAdress === '/twofactor' ? 'active' : ''} to="/twofactor">İki Faktörlü Doğrulama</Link>
        </li>
        <li className="logoff">
          <Link key="logoff" id="logoff" to="/"><Icofont icon="icofont-logout" />Güvenli Çıkış</Link>
        </li>
      </ul>
    </div>
  );
}

export default UserMenu;
