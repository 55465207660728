import { createContext, useState } from 'react';

export const MilesContext = createContext();
const MilesContextProvider = (props) => {

  const [milesPage] = useState([
    {
      miles:[
        {id:"1", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"2", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"3", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"4", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"5", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"6", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"7", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
        {id:"8", category:"game", src:"https://productimages.hepsiburada.net/s/44/500/10807076421682.jpg", title:"PlayStation 5", mile:"300000", brand:"Alavara"},
      ],
    }
  ])
  return(
    <MilesContext.Provider value={{milesPage}}>
      {props.children}
    </MilesContext.Provider>
  )
}
export default MilesContextProvider;
