import Header from 'Component/headers/header';
import Categories from 'Component/globals/categories';
import Pagefit from 'Component/globals/pagefit';
import Footer from 'Component/footers/footer';
import DefaultSports from 'Component/sports/default'


function Sports() {
  return(
    <div className="pageSports">
      <Header/>
      <Categories/>
      <DefaultSports/>
      <Pagefit/>
      <Footer/>
    </div>
  );
}
export default Sports;
