import Header from 'Component/headers/header';
import Categories from 'Component/globals/categories';
import DefaultCategories from 'Component/categories/default';
import Pagefit from 'Component/globals/pagefit';
import Footer from 'Component/footers/footer';


function Prematch() {
  return(
    <div className="pageSports">
      <Header/>
      <Categories/>
      <DefaultCategories/>
      <Pagefit/>
      <Footer/>
    </div>
  );
}
export default Prematch;
