import Icofont from 'react-icofont';
import { Link } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
function Twofacto() {
  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="twofactorPage">
      <Form>
        <Form.Row>
          <Form.Group className="switchTitle" controlId="formGridEmail">
            <Form.Label><img src="/images/icons/twofactor.svg" width="30" alt="2Factor" />İki Faktörlü Doğrulamayı Etkinleştir</Form.Label>
          </Form.Group>

          <Form.Group className="switchButton" controlId="formGridPassword">
          <Form.Check
              type="switch"
              id="twofactor"
            />
          </Form.Group>
        </Form.Row>
      </Form>
      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Twofacto;
