import React from 'react';
import Header from 'Component/headers/header';
import Announcement from 'Component/globals/announcement';
import Slider from 'Component/sliders/slider';
import Categories from 'Component/globals/categories';
import Banner from 'Component/banners/banner';
import BannerReverse from 'Component/banners/bannerReverse';
import Newsletter from 'Component/newsletters/newsletter';
import Pagefit from 'Component/globals/pagefit';
import FooterMenu from 'Component/footers/footerMenu';
import Footer from 'Component/footers/footer';
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';


function LandingPage() {
  const {config} = useContext(ConfigContext);
  const announcement = config.announcement;
  const announcement1 = config.announcement1;
  return (
    <React.Fragment>
      <Header/>
      <Announcement content={announcement} />
      <Slider/>
      <Categories/>
      <Banner/>
      <Announcement content={announcement1} />
      <BannerReverse/>
      <Newsletter/>
      <FooterMenu/>
      <Pagefit/>
      <Footer/>
    </React.Fragment>
  );
}

export default LandingPage;
