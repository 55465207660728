import React, {useContext} from 'react';
import { useHistory } from "react-router-dom";
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
import { MatchsContext } from 'Context/matchsContext';
import { MatchDetailContext } from 'Context/matchdetailContext';

function DefaultSports() {
  let history = useHistory();
  const {matchs, refreshsellmarket} = useContext(MatchsContext);
  const {refreshsMatchId} = useContext(MatchDetailContext);
  const handleSubmit = (selmarket) => {
    refreshsellmarket(selmarket);
  }
  const handleClick = (matchDetailId) => {
    refreshsMatchId(matchDetailId);
    history.push('/match-detail')
  }
  return(
  <div className="mainGetSports">
  {
    matchs.map((matchsItem, index) => (
    <Tabs defaultActiveKey={matchsItem.selmarket} id="getSports" key={index} onSelect={(k) => handleSubmit(k)}>
        {matchsItem.topmarkets.map((topmarketsItem, index) => (
          <Tab eventKey={topmarketsItem[0]} title={topmarketsItem[1]} key={index}>
            {
              matchsItem.status !== 0 ?
              <Row className="sportItem">
                <div className="matchsError notFound">{matchsItem.msg}</div>
              </Row>
              :
              <>
                {matchsItem.events.map((eventsItem, index) => (
                  <Row className="sportItem" key={index}>
                    <Col xs={7} className="resetcol-y teamsInfo">
                      <h4>{eventsItem.ma.path}</h4>
                        <Row className="homeTeam">
                          <Col xs={1} className="resetcol"><Icofont icon="icofont-star"/></Col>
                          <Col xs={9} className="resetcol">{eventsItem.ma.c1}</Col>

                        </Row>
                        <Row className="awayTeam">
                          <Col xs={1} className="resetcol"></Col>
                          <Col xs={9}>{eventsItem.ma.c2}</Col>

                        </Row>
                        <Row className="teamMatchStat">
                          <Col xs={1} className="resetcol"><Icofont icon="icofont-alarm" /></Col>
                          <Col xs={9}>{eventsItem.ma.day} / <span>{eventsItem.ma.hour}</span></Col>
                          <Col xs={2} className="resetcol"><span onClick={() => handleClick(eventsItem.ma.id)}>+{eventsItem.ma.aon}</span></Col>
                        </Row>
                    </Col>
                    <Col xs={5} className="resetcol teamsOdds">
                      {eventsItem.mo.map((moItem, index) => (
                        <div key={index}>
                        <Row className="oddsTitle secondPart">
                        {moItem.odds.map((oddsItem, index) => (
                          <Col className="resetcol" key={index}>{oddsItem.nm}</Col>
                        ))
                        }
                        </Row>
                        <Row className="mainOdds">
                        {moItem.odds.map((oddsItem,index) => (
                          <Col className="resetcol" key={index}>
                            <Button size="sm" variant="light">{oddsItem.val}</Button>
                          </Col>
                        ))
                        }
                        </Row>
                        </div>
                      ))
                      }
                    </Col>
                  </Row>
                ))
                }
              </>
            }
          </Tab>
        ))
      }
    </Tabs>
    ))
  }
  </div>
  );
}
export default DefaultSports;
