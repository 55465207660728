import { createContext, useState, useEffect } from 'react';
import $ from "jquery";
import axios from 'axios';


export const ConfigContext = createContext();
const ConfigContextProvider = (props) => {
  const [config, setConfig] = useState(
    {
      state:0,
      hiddensite:0,
      shopmode:0,
      theme:"default",
      forcelogout:0,
      sitepayments:0,
      language:"en",
      sitedesc:"",
      sitekeyword:"",
      sitelogo:"",
      headertype:"default",
      footertype:"default",
      version:"",
      announcement:"",
      announcement1:"",
      avsrvs:[{
        prematch:0,
        liveodds:0,
        casino:0,
        livecasino:0,
        virtsports:0,
        meteorgames:0,
      }],
      srvinfo:[{
        casinolink:"",
        livecasinolink:"",
        virtsports:"",
      }],
      mainmenu:[
        {
          name:'',
          src:'/',
          menuIcon:"",
        },
      ],
      footermenu:[
        {
          name:'',
          src:'',
          menuIcon:"",
        },
      ]
    }
);

useEffect(() => {
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/globalAPI.php",
  })
  .then(function (response) {
    //handle then
    setConfig(response.data);
  })
  .catch(function (response) {
    //handle error

  });
}, []);



  $("#brand").text(config.sitename);
  $("#desc").text(config.sitedesc);
  $("#keywords").text(config.sitekeyword);
  $("html").attr("lang",config.language);
  return(
    <ConfigContext.Provider value={{config}}>
      {props.children}
    </ConfigContext.Provider>
  )
}
export default ConfigContextProvider;
