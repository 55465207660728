import { Link, useHistory  } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import { useContext, useState } from 'react';
import { ConfigContext } from 'Context/configContext';
import { SessionsContext } from 'Context/sessionsContext';
import { CountriesContext } from 'Context/countriesContext';
import $ from "jquery";
import axios from 'axios';
import { Cookies  } from 'react-cookie';
function LoginPage() {
  let history = useHistory();
  $(".loaderBar").hide();
  const cookies = new Cookies()
  const {config} = useContext(ConfigContext);
  const {refreshSessionsData} = useContext(SessionsContext);
  const {refreshCountriesData} = useContext(CountriesContext);
  const [alertMsg, setAlertMsg] = useState("");
  const [show, setShow] = useState(false);
  const [variant, setVariant] = useState("");
  const AlertModal = () => {
    if (show) {
    return (
      <>
      <Alert variant={variant} onClose={() => setShow(false)} dismissible>
        {alertMsg}
      </Alert>
      </>
    );
  }
  else {
    return <></>;
  }
  }
  const clientLogo = config.sitelogo;
  const [values, setValues] = useState({
        email: '',
        password: '',
  });
  const handleSubmit = async (e) => {
        e.preventDefault();
        $(".loaderBar").show();
        const {email, password} = values;
        const bodyFormData = new FormData();
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);
        axios({
          method: "post",
          url: "https://betmob.cincev.com/api/login.php",
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(function (response) {
          if(response.data.state === 5 || response.data.state === 10 || response.data.state === 20 || response.data.state === 21 || response.data.state === 22) {
            $(".loaderBar").hide();
            setAlertMsg(response.data.msg);
            setShow(true);
            setVariant("danger");
          }
          else {
            $(".loaderBar").hide();
            cookies.set('ta', response.data.client.ta, {maxAge: 3600});
            cookies.set('malvi', response.data.client.clid, {maxAge: 3600});
            cookies.set('malvk', response.data.client.loginkey, {maxAge: 3600});
            cookies.set('sess', response.data.client.sess, {maxAge: 3600});
            setAlertMsg("Login successfuly");
            setShow(true);
            setVariant("success");
            refreshSessionsData(response.data.client.ta, response.data.client.clid, response.data.client.loginkey, response.data.client.sess);
            refreshCountriesData(response.data.client.ta, response.data.client.clid, response.data.client.loginkey, response.data.client.sess);
            setTimeout(() => {
              history.push('/')
            }, 1500);
          }
        })
        .catch(function (response) {
          //handle error

        });
  };
  const handleChange = name => e => {
       setValues({ ...values, [name]: e.target.value });
  };
  return (
    <div className="mainLogin">
    <AlertModal />
    <h5><img src={clientLogo} alt="Brand" /></h5>
    <Form onSubmit={handleSubmit}>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Username</Form.Label>
        <Form.Control type="text" placeholder="Kullanıcı Adı" name="username" value={values.email}  onChange={handleChange('email')} />
      </Form.Group>

      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control type="password" name="password" placeholder="*********" value={values.password}  onChange={handleChange('password')} />
      </Form.Group>
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check type="checkbox" label="Remember Me" />
        <div className="lostPassw"><Link to="/lost-password">Lost Password</Link></div>
        <div className="clearfix"></div>
      </Form.Group>
      <div className="clearfix"></div>
      <Button variant="primary" type="submit">
        Login!
      </Button>
    </Form>
    <div className="newRegister">
      <span>You dont have account?</span> <Link to="/register">Register</Link>
    </div>
    </div>
  );
}

export default LoginPage;
