import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
// import $ from "jquery";

export const MatchDetailContext = createContext();
const MatchDetailContextProvider = (props) => {
const [matchDetail, setMatchDetail] = useState({
  status:0,
  event:{
    ma:{
      id:0
    },
    mo:[{
      status:0,
      markets:[{
        status:0,
        odds:[{
          status:0,
        }],
      }]
    }]
  }
});
const [matchDetailId, setMatchDetailId] = useState({
  lang:"en",
  tz:0,
  evid:0,
});
const refreshsMatchId = (matchDetailIds) => {
      setMatchDetailId({...matchDetailId, evid:matchDetailIds});
};

useEffect(() => {
// $(".loaderBar").show(); *
  axios({
    method: "get",
    url: "https://betmob.cincev.com/api/getSingleEvent.php?lang="+matchDetailId.lang+"&tz="+matchDetailId.tz+"&evid="+matchDetailId.evid,
  })
  .then(function (response) {
    //handle then
    setMatchDetail(response.data);
//  $(".loaderBar").hide();
  })
  .catch(function (response) {
    //handle error

  });
}, [matchDetailId.lang, matchDetailId.tz, matchDetailId.evid]);


  return(
    <MatchDetailContext.Provider value={{matchDetail, refreshsMatchId}}>
      {props.children}
    </MatchDetailContext.Provider>
  )
}
export default MatchDetailContextProvider;
