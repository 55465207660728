import { createContext, useState } from 'react';

export const CampaignsContext = createContext();
const CampaignsContextProvider = (props) => {

  const [campaignsPage] = useState([
    {
      campaigns:[
        {src:"http://bonusparki.com/assets/uploads/settings/betpark_deneme_bonusu2.jpg", linkto:"/", name:"Üye olana 20 TL", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
        {src:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUBQpVGZ8GFJJEj7FP3Q3x_sbxuH3f_TGDsbaG76eqcEeri8Dx9chtJBKeaPALhTD6VaY&usqp=CAU", linkto:"/", name:"%100 Risksiz Bahis", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
        {src:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT2ZJjqPsveWhuZauncEZpQG1WAk8FMEBwLEabDB5uqM3qH50NxtiM4VGiBVEnY5LSWVYQ&usqp=CAU", linkto:"/", name:"Kupon yap kazan", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
        {src:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRnquWoA62ePEvUA3zG2BU7Nia1PaGCxdj6agsHJSaT6mBawZc7Ipr85U3oirQdyH1qvXI&usqp=CAU", linkto:"/", name:"Kayıp Bonusu", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
        {src:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTXJoSHITouKntm6ZC1FXeXmqabOzkah16su89JzoYlMTi_OPqCGWFPwl2SIKKYuQij7Ds&usqp=CAU", linkto:"/", name:"Arkadaşına Öner Kazan!", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
        {src:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRPpSwLvcUrZJQCAZlVbrn2MzZvV2bp6ipeTTOTl_w-zDLoNbqRsQzUg5rCiSCsUOgf_-Q&usqp=CAU", linkto:"/", name:"Son Reklam Başlığı", content:"<strong>Merhaba Kampanya</strong><p>Buraya yeni bir veri geldi</p><p>Yeni bir paragraf verdim</p>"},
      ],
    }
  ])
  return(
    <CampaignsContext.Provider value={{campaignsPage}}>
      {props.children}
    </CampaignsContext.Provider>
  )
}
export default CampaignsContextProvider;
