import Icofont from 'react-icofont';
import { Link, useHistory } from "react-router-dom";
import UserMenu from 'Module/user-menu';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import { BettingSlipsContext } from 'Context/bettingSlips';
import { useCookies } from 'react-cookie';
function Mybets() {
  const {refreshBettingSlipsData, updateCouponFilter} = useContext(BettingSlipsContext);
  let history = useHistory();
  const [cookies] = useCookies([
    'malvi',
  ], []);
  if(cookies.malvi === undefined) {
    history.push('/login')
  }
  const {slips} = useContext(BettingSlipsContext);


  const handleChange = name => e => {
      updateCouponFilter({toPage:100, [name]: e.target.value, addEvents:0 })
  };

  const handleClick = (value) => {
      refreshBettingSlipsData(value);
  };


  const handleSubmit = (e) => {
      e.preventDefault();
      refreshBettingSlipsData()
  }

  return (
    <div className="mainBalances">
      <div className="backtoHome">
        <Link to="/"><Icofont icon="icofont-close-circled" /></Link>
      </div>
      <UserMenu />
      <div className="mybetsPage">
      <Form onSubmit={handleSubmit}>
        <Form.Group as={Col} controlId="formGridEmail">
          <Form.Control as="select" className="allType" onChange={handleChange('byStat')}>
            <option name="byStat" value="-1">Tüm Bahisler</option>
            <option name="byStat" value="0">Açık</option>
            <option name="byStat" value="1">Kazanan</option>
            <option name="byStat" value="3">Kaybeden</option>
            <option name="byStat" value="2">İptal</option>
            <option name="byStat" value="5">CashOut</option>
          </Form.Control>
        </Form.Group>
        {/* <Form.Row>
          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label>Bahis Türü</Form.Label>
            <Form.Control as="select" size="sm" onChange={handleChange('byStat')}>
              <option name="byStat" value="-1">Tüm Bahisler</option>
              <option name="byStat" value="0">Açık</option>
              <option name="byStat" value="1">Kazanan</option>
              <option name="byStat" value="3">Kaybeden</option>
              <option name="byStat" value="2">İptal</option>
              <option name="byStat" value="5">CashOut</option>
            </Form.Control>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label>Göster</Form.Label>
            <Form.Control as="select" size="sm" onChange={handleChange('toPage')}>
              <option name="byStat" value="10">10</option>
              <option name="byStat" value="50">50</option>
              <option name="byStat" value="100">100</option>
              <option name="byStat" value="250">250</option>
              <option name="byStat" value="500">500</option>
              <option name="byStat" value="9999">Tümü</option>
            </Form.Control>
          </Form.Group>
        </Form.Row>*/}
        <p className="submitBtn">
          <Button variant="primary" type="submit">Göster</Button>
        </p>
      </Form>

      {

        slips.status === 3 || slips.status === null ?
        <div className="betTicket notFound">{slips.msg}</div>
        :
        slips.tickets.map((slipsItem, index) => (
          <div className="betTicket" key={index}>
            <p>{slipsItem.combstring}</p>
            <p>{slipsItem.status === 0 ? "OPEN" : "" || slipsItem.status === 1 ? "WINNING" : "" ||  slipsItem.status === 2 ? "CANCELED" : "" || slipsItem.status === 3 ? "LOSING" : "" ||  slipsItem.status === 4 ? "WINNING" : "" || slipsItem.status === 5 ? "CASHOUT" : ""}</p>
            <p><strong>ID:</strong> {slipsItem.tkid}</p>
            <p>{slipsItem.createdon}</p>
            <p>Bahis Miktarı</p>
            <p>{slipsItem.betval}</p>
            <p>Oran</p>
            <p className="ticketOdds">{slipsItem.totalcoef}</p>
            <p>Kazanılan Tutar</p>
            <p className={slipsItem.status === 1 || slipsItem.status === 5 || slipsItem.status === 4 ? "ticketwin" : "ticketlose"}>{slipsItem.reward === "" ? "0" : slipsItem.reward}</p>
            <div className="clearfix"></div>
            <Accordion defaultActiveKey="0">
              <div className="caretDown">
                <Accordion.Toggle eventKey={slipsItem.tkid} onClick={() => handleClick(slipsItem.tkid)}>
                  <Icofont icon="icofont-caret-down"/>
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey={slipsItem.tkid}>
                <div className="ticketOpen">
                {slipsItem.events.map((eventsItem, index) => (

                  <div className="ticketMatch" key={index}>
                    <p>{eventsItem.matchpath}</p>
                    <p>{eventsItem.matchminute === null ? eventsItem.matchdate : eventsItem.matchminute + " '" }</p>
                    <p>{eventsItem.t1} <br/> {eventsItem.t2}</p>
                    <p>{eventsItem.matchresult === "" ? "- : -" : eventsItem.matchresult }</p>
                    <p>{eventsItem.marketname}</p>
                    <p>{eventsItem.oddname}</p>
                    <p>Oran</p>
                    <p>{eventsItem.oddval}</p>
                    <p>Durum</p>
                    <p>{eventsItem.win === "0" ? "Kaybetti" : "Bekliyor" || eventsItem.win === "1" ? "Kazandı" : "Bekliyor" || eventsItem.wim === "2" ? "Geçersiz" : "Bekliyor" || eventsItem.win === "-1" ? "İptal Edildi" : "Bekliyor"}</p>
                    <div className="clearfix"></div>
                  </div>
                ))}
                </div>
              </Accordion.Collapse>
            </Accordion>
          </div>
        ))
      }

      </div>
      <div className="clearfix"></div>
    </div>
  );
}

export default Mybets;
