import DefaultFooter from 'Component/footers/type/default';
import ScrollFooter from 'Component/footers/type/scroll';
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function Footer() {
  const {config} = useContext(ConfigContext);
  const type = config.footertype;
  const footerType = () => {
      if (type === "default") {
        return <DefaultFooter/>;
      } else if (type === "scroll") {
        return <ScrollFooter/>;
      }
      else {
        return <DefaultFooter/>;
      }
    }
  return (
    <>
    {footerType()}
    </>
  );
}


export default Footer;
