import { Link } from "react-router-dom";
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function DefaultFooter() {
  const {config} = useContext(ConfigContext);
  const footerMenu = config.footermenu;
  const staticMenu = config.avsrvs;
  const prematch = staticMenu.prematch;
  const liveodds = staticMenu.liveodds;
  const prematchMenu = () => {
      if (prematch) {
        return(
          <Link to="/prematch"><img src="/images/icons/sport.png" alt="Sport" width="24" /></Link>
        );
      } else {
        return;
      }
  }
  const liveoddsMenu = () => {
      if (liveodds) {
        return(
          <Link to="/live"><img src="/images/icons/live.png" alt="Live" width="24" /></Link>
        );
      } else {
        return;
      }
  }
  return (
    <footer className="mainFooter">
    <Link to="/"><img src="/images/icons/home.png" alt="Home" width="24" /></Link>
    {prematchMenu()}
    {liveoddsMenu()}
    <Link to="/coupons"><img src="/images/icons/coupon.png" alt="Coupon" width="24" /></Link>
    {
      footerMenu.map((menuItem, index) => (
        <Link key={menuItem.name} to={menuItem.src}><img src={menuItem.menuIcon} width="24" alt={menuItem.name} /></Link>
      ))
    }
    <Link to="/support"><img src="/images/icons/support.png" alt="Support" width="24" /></Link>
    </footer>
  );
}


export default DefaultFooter;
