import { createContext, useState, useEffect } from 'react';
import axios from 'axios';
import $ from "jquery";
import { useCookies } from 'react-cookie';

export const BettingSlipsContext = createContext();
const BettingSlipsContextProvider = (props) => {
  const [cookies] = useCookies([
    'malvi',
    'malvk',
  ], []);
  const [couponDetails, setCouponDetails] = useState ({
    toPage: 100,
    byStat: -1,
    addEvents: 0
  })
  const [slips, setSlips] = useState({
    status:null,
  });

  const updateCouponFilter = (byStat) => {
    setCouponDetails(byStat);
  }


  const refreshBettingSlipsData = (value) => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/client/betting_slips.php?toPage="+couponDetails.toPage+"&byStat="+couponDetails.byStat+"&addEvents="+value,
      headers: {
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
      },
    })
    .then(function (response) {
      //handle then
      setSlips(response.data);
    },[])
    .catch(function (response) {
      //handle error
    });
  };

  useEffect(() => {
    axios({
      method: "get",
      url: "https://betmob.cincev.com/api/client/betting_slips.php?toPage=100&byStat=-1&addEvents=0",
      headers: {
        "malvi": cookies.malvi,
        "malvk": cookies.malvk,
      },
    })
    .then(function (response) {
      //handle then
      setSlips(response.data);
      $(".loaderBar").hide();
    },[])
    .catch(function (response) {
      //handle error

    });
  }, [cookies.malvi, cookies.malvk]);



  return(
    <BettingSlipsContext.Provider value={{slips, refreshBettingSlipsData, updateCouponFilter}}>
      {props.children}
    </BettingSlipsContext.Provider>
  )
}
export default BettingSlipsContextProvider;
