import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';
import { LoginPageContext } from 'Context/loginContext';
import LandingPageContextProvider from 'Context/landingpageContext';
import CategoriesContextProvider from 'Context/categoriesContext';
import SportsContextProvider from 'Context/sportsContext';
import MatchsContextProvider from 'Context/matchsContext';
import MatchDetailContextProvider from 'Context/matchdetailContext';
import LoginPageContextProvider from 'Context/loginContext';
import CampaignsContextProvider from 'Context/campaignsContext';
import MilesContextProvider from 'Context/milesContext';
import BonusContextProvider from 'Context/bonusContext';
import TimezonesContextProvider from 'Context/timezonesContext';
import BettingSlipsContextProvider from 'Context/bettingSlips';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import LandingPage from 'Page/landingPage';
import LoginPage from 'Page/loginPage';
import RegisterPage from 'Page/registerPage';
import LostPassword from 'Page/lostPassword';
import Campaigns from 'Page/campaigns';
import Miles from 'Page/miles';
import Balances from 'Page/balances';
import Mybets from 'Page/mybets';
import Mybonus from 'Page/mybonus';
import Myaccount from 'Page/myaccount';
import Changepassword from 'Page/changePassword';
import Approvedaccount from 'Page/approvedAccount';
import Twofactor from 'Page/twofactor';
import Prematch from 'Page/prematch';
import Sports from 'Page/sports';
import MatchDetail from 'Page/match-detail';

function WebRouter() {
  const {config} = useContext(ConfigContext);
  const {userInfo} = useContext(LoginPageContext);

  const isHidden = config.hiddensite;
  const renderedPage = () => {
      if (isHidden) {
        return (
          <LoginPageContextProvider>
            <LoginPage/>
          </LoginPageContextProvider>
        );
      } else {
        return (
          <LandingPageContextProvider>
            <CategoriesContextProvider>
              <SportsContextProvider>
                <MatchsContextProvider>
                  <LandingPage/>
                </MatchsContextProvider>
              </SportsContextProvider>
            </CategoriesContextProvider>
          </LandingPageContextProvider>
        );
      }
    }
  return (
    <Router config={config} userInfo={userInfo} >
        <Switch>
          <Route path="/match-detail">
            <LandingPageContextProvider>
              <CategoriesContextProvider>
                <SportsContextProvider>
                  <MatchsContextProvider>
                    <MatchDetailContextProvider>
                      <MatchDetail />
                    </MatchDetailContextProvider>
                  </MatchsContextProvider>
                </SportsContextProvider>
              </CategoriesContextProvider>
            </LandingPageContextProvider>
          </Route>
          <Route path="/sports">
            <LandingPageContextProvider>
              <CategoriesContextProvider>
                <SportsContextProvider>
                  <MatchsContextProvider>
                    <MatchDetailContextProvider>
                      <Sports />
                    </MatchDetailContextProvider>
                  </MatchsContextProvider>
                </SportsContextProvider>
              </CategoriesContextProvider>
            </LandingPageContextProvider>
          </Route>
          <Route path="/prematch">
            <LandingPageContextProvider>
              <CategoriesContextProvider>
                <SportsContextProvider>
                  <MatchsContextProvider>
                    <Prematch />
                  </MatchsContextProvider>
                </SportsContextProvider>
              </CategoriesContextProvider>
            </LandingPageContextProvider>
          </Route>
          <Route path="/twofactor">
            <Twofactor />
          </Route>
          <Route path="/approved-account">
            <Approvedaccount />
          </Route>
          <Route path="/change-password">
            <Changepassword />
          </Route>
          <Route path="/myaccount">
            <TimezonesContextProvider>
              <Myaccount />
            </TimezonesContextProvider>
          </Route>
          <Route path="/mybonus">
            <BonusContextProvider>
              <Mybonus />
            </BonusContextProvider>
          </Route>
          <Route path="/mybets">
            <BettingSlipsContextProvider>
              <Mybets />
            </BettingSlipsContextProvider>
          </Route>
          <Route path="/balances">
            <Balances />
          </Route>
          <Route path="/gifts">
            <MilesContextProvider>
              <Miles />
            </MilesContextProvider>
          </Route>
          <Route path="/campaigns">
            <CampaignsContextProvider>
              <Campaigns />
            </CampaignsContextProvider>
          </Route>
          <Route path="/landingpage">
            <LandingPageContextProvider>
              <CategoriesContextProvider>
                <SportsContextProvider>
                  <MatchsContextProvider>
                    <LandingPage/>
                  </MatchsContextProvider>
                </SportsContextProvider>
              </CategoriesContextProvider>
            </LandingPageContextProvider>
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/register">
            <RegisterPage />
          </Route>
          <Route path="/lost-password">
            <LostPassword />
          </Route>
          <Route path="/">
            {renderedPage()}
          </Route>
        </Switch>
    </Router>
  );
}

export default WebRouter;
