import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Button from 'react-bootstrap/Button';
import Icofont from 'react-icofont';
function Deposit() {
  return (
    <Tabs defaultActiveKey="udeposit" id="userTabs" className="userTabs">
    <Tab eventKey="udeposit" title="Para Yatır">
      <Tabs defaultActiveKey="quickqr" id="paymentMode" className="paymentMode">
        <Tab eventKey="quickqr" title="Anında QR">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="quickcreditcard" title="Anında Kredi Kartı">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="quickeft" title="Anında Havale/EFT">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="quickpapara" title="Anında PaPara">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="payfix" title="PayFix">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="favexs" title="Favexs Pro">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="cepbank" title="CepBank">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="qrcode" title="QR Kod">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="parapara" title="PaPara">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="eft" title="Havale/EFT">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="jeton" title="Jeton">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
        <Tab eventKey="coin" title="Coin">
          <div className="paymentTabContent">
            <p>Aşağıdaki butona basarak işleminize devam edebilirsiniz</p>
            <Button variant="primary">Para Yatır</Button>
          </div>
        </Tab>
      </Tabs>
    </Tab>
    <Tab eventKey="udepositInfo" title="Para Yatırım Durumu">
      <div className="depositStatus">
        <p><strong>Yatırılma Biçimi:</strong> PaPara</p>
        <p><strong>Yatırılan Tutar:</strong> 1000TL</p>
        <p><strong>Yatırılma Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Beklemede <Icofont icon="icofont-check-circled" className="pending" /></p>
      </div>
      <div className="depositStatus">
        <p><strong>Yatırılma Biçimi:</strong> PaPara</p>
        <p><strong>Yatırılan Tutar:</strong> 1000TL</p>
        <p><strong>Yatırılma Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Onaylandı <Icofont icon="icofont-check-circled" className="success" /></p>
      </div>
      <div className="depositStatus">
        <p><strong>Yatırılma Biçimi:</strong> PaPara</p>
        <p><strong>Yatırılan Tutar:</strong> 1000TL</p>
        <p><strong>Yatırılma Tarihi:</strong> 14/06/2021 - 12:16</p>
        <p><strong>Durum:</strong> Onaylanmadı <Icofont icon="icofont-check-circled" className="danger" /></p>
      </div>
    </Tab>
    </Tabs>
  );
}
export default Deposit;
