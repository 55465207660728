import { Link } from "react-router-dom";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useContext } from 'react';
import { ConfigContext } from 'Context/configContext';

function LostPassword() {
  const {config} = useContext(ConfigContext);
  const clientLogo = config.sitelogo;
  return (
    <div className="mainLogin">
    <h5><img src={clientLogo} alt="Brand" /></h5>
    <Form>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="example@example.com" />
        <Form.Text className="text-muted">
          Wrong Formatted!
        </Form.Text>
      </Form.Group>
      <Button variant="primary" type="submit">
        Send Password!
      </Button>
    </Form>
    <div className="newRegister">
      <span>You dont have account?</span> <Link to="/register">Register</Link>
    </div>
    <div className="newRegister" style={{ marginTop: "10px"}}>
      <span>Do you have account?</span> <Link to="/login">Login</Link>
    </div>
    </div>
  );
}

export default LostPassword;
